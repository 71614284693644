import axios from "axios";
import { config_master } from "app/config";
import axiosInstance from "app/interceptors";

const headers = {
  accept: `text/plain`,
  // "Content-Type": "application/json",
};

export const getIssueFocuses = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/FokusIsu/list`,
    params,
    {
      headers,
    }
  );
};
export const getCollaborationFields = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/BidangKerjasama/list`,
    params,
    { headers }
  );
};
export const getInstitutionTypes = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/JenisLembaga/list`,
    params,
    {
      headers,
    }
  );
};
export const getStrategicPlans = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/RencanaStrategis/list`,
    params,
    { headers }
  );
};

export const addNewStrategicPlans = (params) => {
  return axiosInstance.post(
    `${config_master.BASE_URL}/v1/RencanaStrategis`,
    params,
    {
      headers,
    }
  );
};

export const addNewPosition = (params) => {
  return axiosInstance.post(
    `${config_master.BASE_URL}/v1/JabatanExternal`,
    params,
    {
      headers,
    }
  );
};

export const getThematicProgrammes = (params) => {
  return axiosInstance.get(
    `${config_master.BASE_URL}/v1/ThematicProgrammes/list`,
    params,
    { headers }
  );
};

export const postToGetThematicProgrammes = (params) => {
  return axiosInstance.post(
    `${config_master.BASE_URL}/v1/ThematicProgrammes/datatable`,
    params,
    { headers }
  );
};

// export const getJobPerformances = (params) => {
//   return axiosInstance.get(
//     `${config_master.BASE_URL}/v1/FokusIsu/list`,
//     params,
//   );
// };
// export const getToolCategory = (params) => {
//   return axiosInstance.get(
//     `${config_master.BASE_URL}/v1/FokusIsu/list`,
//     params,
//   );
// };
// export const getPublicAccountingFirm = (params) => {
//   return axiosInstance.get(
//     `${config_master.BASE_URL}/v1/FokusIsu/list`,
//     params,
//   );
// };
// export const getProjectPosition = (params) => {
//   return axiosInstance.get(
//     `${config_master.BASE_URL}/v1/FokusIsu/list`,
//     params,
//   );
// };
