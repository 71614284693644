import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ReactSelectCreatable from "react-select/creatable";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { BiArrowBack, BiPlus, BiTrash } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import _, { findLastIndex } from "lodash";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Button,
  ConfirmationModal,
  InputForm,
  InputFormRadio,
} from "components";
import AtomSelect from "components/atoms/Select";
import { SuccessToast } from "components/atoms/Toast";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import BreadCrumbs from "components/molecules/Breadcrumbs";
import CardForm from "components/molecules/CardForm";
import InputFormDropzone from "components/molecules/InputFormDropzone";
import SelectFDBCity from "components/organisms/inputs/SelectFDBCity";
import SelectFDBProvince from "components/organisms/inputs/SelectFDBProvince";
import SelectFDBDistrict from "components/organisms/inputs/SelectFDBDistrict";
import SelectFDBVillage from "components/organisms/inputs/SelectFDBVillage";
import { getListAWP, getListInvestment } from "services/danaProgram/awpService";
import { getListISPService } from "services/masterData/isp";
import {
  getCityService,
  getDistrictService,
  getProvinceService,
  getWardService,
} from "services/masterData/region";
import { fileUpload } from "services/danaProgram/fileService";
import { getDetailInvestmentPlan } from "services/danaProgram/noteConcept";
import axiosInstance from "app/interceptors";
import { config_daprog } from "app/config";

const validationSchema = yup.object({
  awp_id: yup.object().required("Proyek wajib diisi"),
  proposal_title: yup.string().required("Judul Proposal wajib diisi"),
  description_proposal: yup.string().required("Deskripsi Proposal wajib diisi"),
  main_activity: yup.array().of(
    yup.object().shape({
      val: yup.string().required("Aktivitas Utama wajib diisi"),
    })
  ),
  activity_goals: yup.array().of(
    yup.object().shape({
      val: yup.string().required("Tujuan Kegiatan wajib diisi"),
    })
  ),
  is_conversion_area: yup.boolean().required("Kawasan konservasi wajib diisi"),
  location_conversion_area: yup.string().when("is_conversion_area", {
    is: true,
    then: () => yup.string().required("Kawasan Konservasi wajib diisi"),
    otherwise: () => yup.string(),
  }),
  is_involve_indigenous_comunities: yup
    .boolean()
    .required("Kegiatan ini melibatkan masyarakat adat wajib diisi"),
  comunities_name: yup.string().when("is_involve_indigenous_comunities", {
    is: true,
    then: () =>
      yup
        .string()
        .required("Masyarakat hukum adat yang dilibatkan wajib diisi"),
    otherwise: () => yup.string(),
  }),
  location: yup.array().of(
    yup.object().shape({
      province: yup.object().required("Provinsi wajib diisi"),
      cities: yup.object().required("Kota wajib diisi"),
    })
  ),
  file_beneficiaries_document: yup
    .array()
    .required("Dokumen Daftar Beneficiaries wajib diisi"),
  safeGuards: yup.array().required("Safeguard wajib diisi"),
  fund_application_value: yup
    .string()
    .required("Nilai Pengajuan Dana wajib diisi"),
  rab_documents: yup.array().required("Dokumen RAB wajib diisi"),
  applicant_information: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Nama wajib diisi"),
      NIDN_NIP: yup.string().required("NIDN/NIP/NIK wajib diisi"),
      position: yup.string().required("Posisi wajib diisi"),
    })
  ),
});

const KonsepNotesForm = () => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      awp_id: null,
      proposal_title: "",
      description_proposal: "",
      main_activity: [{ val: "" }],
      activity_goals: [{ val: "" }],
      location: [
        {
          province: null,
          cities: null,
          district: null,
          village: null,
          latitude: "",
          longitude: "",
        },
      ],
      regional_restrictions: "",
      is_conversion_area: null,
      location_conversion_area: "",
      already_have_permission: null,
      is_involve_indigenous_comunities: null,
      is_involve_beneficiaries_from_woman: null,
      comunities_name: "",
      total_beneficiaries: {
        female: "",
        male: "",
        total: "",
      },
      file_beneficiaries_document: undefined,
      safeGuards: null,
      fund_application_value: "",
      rab_documents: undefined,
      applicant_information: [
        { name: "", NIDN_NIP: null, description: "", position: "" },
        { name: "", NIDN_NIP: null, description: "", position: "" },
      ],
      additional_document: [],
    },
    mode: "all",
  });
  const { control } = methods;
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [optionAWP, setOptionAWP] = useState([]);
  const [limit, setLimit] = useState(10);
  const [hasMore, setHasMore] = useState(false);
  const userId = useSelector((state) => state.auth.user.UserId);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [outcomeOption, setOutcomeOption] = useState([]);
  const [outputOption, setOutputOption] = useState([]);
  const [ispOption, setIspOption] = useState([]);

  const [provinces, setProvinces] = useState();
  const [cities, setCities] = useState();
  const [districts, setDistricts] = useState();
  const [wards, setWards] = useState();

  const fetchProjectProposal = async () => {
    const response = await getListInvestment({
      page: page,
      limit: limit,
      status: "dikirim",
      distribution_mechanism: "Penyaluran Tidak Langsung",
    });
    const data = await response?.data?.data;

    const listAWP = data
      ? data.map((el, i) => ({
        value: el?._id,
        label: el?.name,
        investment_id: el?._id,
      }))
      : [];

    setLastPage(response?.data?.data?.lastPage);

    setOptionAWP((prevState) => [...prevState, ...listAWP]);
  };

  const fetchDetailInvestmentPlan = async (id) => {
    const response = await getDetailInvestmentPlan({ id: id });

    let arrOutcome = [];
    let arrOutput = [];

    if (!_.isEmpty(response?.data?.data?.step_3)) {
      response?.data?.data?.step_3?.map((el) => {
        if (el?.outcome)
          arrOutcome.push({ value: el.outcome, label: el.outcome });
        if (el?.output) arrOutput.push({ value: el.output, label: el.output });
      });
    } else {
      arrOutcome = [];
      arrOutput = [];
    }

    setOutcomeOption(arrOutcome);
    setOutputOption(arrOutput);
  };

  const fetchListIsp = async () => {
    const response = await getListISPService();

    const populated = response?.data?.data?.map((el, i) => ({
      value: el?.deskripsi,
      label: el?.deskripsi,
    }));

    setIspOption(populated);
  };

  const convertMoney = (value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes(".") ? oriValue.split(".").join("") : oriValue;
    let modifValue = "";
    let count = 1;
    if (oriValue !== "" && oriValue.length > 3) {
      const splitValue = oriValue.split("");
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }
    return modifValue;
  };

  useEffect(() => {
    fetchProjectProposal();
  }, [page]);

  useEffect(() => {
    fetchListIsp();
  }, []);

  const submitForm = () => {
    setConfirmModal(true);
  };

  const items = [
    {
      label: "Daftar Permohonan Konsep Notes",
      path: "/proposal/konsep-notes",
    },
    { label: "Tambah Konsep Note" },
  ];

  const {
    fields: mainActivityFields,
    remove: removeMainActivityFields,
    append: appendMainActivityFields,
  } = useFieldArray({
    control: methods.control,
    name: "main_activity",
  });

  const {
    fields: activityGoalFields,
    remove: removeActivityGoalFields,
    append: appendActivityGoalFields,
  } = useFieldArray({
    control: methods.control,
    name: "activity_goals",
  });

  const {
    fields: applicationInformationFields,
    remove: removeApplicationInformationFields,
    append: appendApplicationInformationFields,
  } = useFieldArray({
    control: methods.control,
    name: "applicant_information",
  });

  const fileUploadHelper = (listFile) => {
    let temp = [];

    return new Promise(async (resolve, reject) => {
      try {
        for (let file of listFile) {
          const formData = new FormData();
          formData.append("file", file);

          const res = await fileUpload(formData);

          const data = await res.data.data;

          const payload = {
            fileId: data.id,
            fileName: data.name,
            fileSize: data.size,
            mimeType: data.mimeType,
            path: data.url,
          };

          temp.push(payload);
        }

        resolve(temp);
      } catch (err) {
        reject(err);
      }
    });
  };

  const onCreate = async (data) => {
    let investment = data.awp_id;
    let paramMainActivity = data.main_activity.map((el) => el?.val);
    let paramActivityGoal = data.activity_goals.map((el) => el?.val);

    // destructuring and split param investment_id
    data.investment_plan_id = investment.value;
    data.project_name = investment.label;

    data.main_activity = paramMainActivity;
    data.activity_goals = paramActivityGoal;

    let postFileRegionalRestriction,
      postFileRabDoc,
      postFileBeneficiariesDoc,
      postAdditionalDoc;

    // this field is optional so that's set up condition
    if (data.regional_restrictions) {
      postFileRegionalRestriction = await fileUploadHelper(
        data.regional_restrictions
      );
    }

    postFileRabDoc = await fileUploadHelper(data.rab_documents);

    postFileBeneficiariesDoc = await fileUploadHelper(
      data.file_beneficiaries_document
    );

    // this field is optional so that's set up condition
    if (data.additional_document) {
      postAdditionalDoc = await fileUploadHelper(data.additional_document);
    }

    let [
      regionalRestrictions,
      rabDocuments,
      beneficiariesDocuments,
      additionalDocuments,
    ] = await Promise.all([
      postFileRegionalRestriction,
      postFileRabDoc,
      postFileBeneficiariesDoc,
      postAdditionalDoc,
    ]);

    data.user_id = userId;
    data.regional_restrictions = regionalRestrictions;
    data.rab_documents = rabDocuments;
    data.file_beneficiaries_document = beneficiariesDocuments;
    data.additional_document = additionalDocuments;
    data.already_have_permission = data.already_have_permission ? true : false;

    try {
      const response = await axiosInstance.post(
        `${config_daprog.BASE_URL}/v1/notes`,
        data
      );

      setSuccess(true);

      setConfirmModal(!confirmModal);

      navigate("/proposal/konsep-notes");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let temp = methods.watch("total_beneficiaries");

    temp["total"] =
      temp["male"] || temp["female"]
        ? parseInt(temp["male"] || "0") + parseInt(temp["female"] || "0")
        : "";

    methods.setValue("total_beneficiaries", temp);
  }, [
    methods.watch("total_beneficiaries")?.female,
    methods.watch("total_beneficiaries")?.male,
  ]);

  useEffect(() => {
    const id = methods.watch("awp_id")?.investment_id;

    id && fetchDetailInvestmentPlan(id);
  }, [methods.watch("awp_id")]);

  const reachBottom = () => {
    if (page < lastPage) {
      setPage(page + 1);
    }
  };

  const renderModal = () => {
    return (
      <ConfirmationModal
        open={confirmModal}
        onCancel={setConfirmModal}
        onSubmit={() => onCreate(methods.getValues())}
        cancelLabel={"Tidak, Ubah Data"}
        confirmLabel={"Ya, Kirimkan"}
      >
        <div className="text-lg font-semibold">
          Anda yakin akan mengirimkan semua data ini?
        </div>
        <div className="text-sm">
          Dengan ini kami menyatakan bahwa Seluruh data yang kami berikan pada
          formulir ini adalah benar adanya. Jika dikemudian hari BPDLH menemukan
          unsur kebohongan dan atau pemalsuan data, kami siap dan bersedia untuk
          diajukan ke ranah hukum sesuai dengan undang-undang yang berlaku.
        </div>
      </ConfirmationModal>
    );
  };

  const getRegion = useMutation(async (name) => {
    try {
      const provinceResponse = await getProvinceService();
      _mapProvince(provinceResponse?.data?.data);
      const citiesResponse = await getCityService();
      _mapCity(citiesResponse?.data?.data);
      const districtResponse = await getDistrictService();
      _mapDistrict(districtResponse?.data?.data);
      const wardResponse = await getWardService();
      _mapWard(wardResponse?.data?.data);
    } catch (error) {
      throw error;
    }
  });


  const _mapProvince = (data) => {
    const temp = data?.map((item, index) => ({
      label: item.namaProvinsi,
      value: item.id,
    }));

    setProvinces(temp);
  };

  const _mapCity = (data) => {
    if (methods.getValues("location[0].province")) {
      const transformedData = data?.map((item, index) => ({
        label: item.namaKabupatenKota,
        value: item.id,
        province: item.namaProvinsi,
      }));

      const filteredData = transformedData.filter(
        (item) =>
          item.province === methods.getValues("location[0].province").label
      );

      setCities(filteredData);
    } else {
      const transformedData = data?.map((item, index) => ({
        label: item.namaKabupatenKota,
        value: item.id,
        province: item.namaProvinsi,
      }));
      setCities(transformedData);
    }
  };

  const _mapDistrict = (data) => {
    if (methods.getValues("location[0].cities")) {
      const transformedData = data?.map((item, index) => ({
        label: item.namaKecamatan,
        value: item.id,
        city: item.namaKabupatenKota,
      }));

      const filteredData = transformedData.filter(
        (item) => item.city === methods.getValues("location[0].cities").label
      );

      setDistricts(filteredData);
    } else {
      const transformedData = data?.map((item, index) => ({
        label: item.namaKecamatan,
        value: item.id,
        city: item.namaKabupatenKota,
      }));
      setDistricts(transformedData);
    }
  };

  const _mapWard = (data) => {
    if (methods.getValues("location[0].district")) {
      const transformedData = data?.map((item, index) => ({
        label: item.namaKelurahan,
        value: item.id,
        district: item.namaKecamatan,
      }));
      const filteredData = transformedData.filter(
        (item) =>
          item.district === methods.getValues("location[0].district").label
      );

      setWards(filteredData);
    } else {
      const transformedData = data?.map((item, index) => ({
        label: item.namaKelurahan,
        value: item.id,
        district: item.namaKecamatan,
      }));
      setWards(transformedData);
    }
  };

  const callback = () => {
    getRegion.mutate();
  };

  useEffect(() => {
    callback();
  }, [
    methods.watch("location[0].province"),
    methods.watch("location[0].cities"),
    methods.watch("location[0].district"),
    methods.watch("location[0].village"),
  ]);

  const {
    fields: locationFields,
    append: appendLocation,
    remove: removeLocation,
  } = useFieldArray({
    control,
    name: "location",
  });

  return (
    <>
      {/* Modal */}
      {renderModal()}

      {success && (
        <SuccessToast
          onClose={() => setSuccess(!success)}
          message={"Data Berhasil Dikirim!"}
          message_description="Data yang anda masukkan sudah berhasil dikirim"
        />
      )}

      <div className="flex flex-col bg-white p-10 gap-10 self-stretch rounded-xl">
        {/* Header Section */}
        <div className="flex flex-col gap-4">
          <BreadCrumbs routes={items} />

          <h1 className="text-gray-900 text-3xl font-medium">
            Tambah Konsep Note
          </h1>

          <hr />
        </div>

        {/* Information Bar */}
        <div className="flex bg-[#FFFCF5] self-stretch p-4 gap-3 border border-[#F5AE70] rounded-xl">
          <FiAlertTriangle color="#BE600E" size={20} />
          <div className="flex flex-col gap-1">
            <p className="font-medium text-[#8F480A] text-sm">Lengkapi Data</p>
            <p className="text-sm">
              Silakan isi semua data dibawah ini untuk bisa melanjutkan
              pengisian ke tahap selanjutnya
            </p>
          </div>
        </div>

        <FormProvider {...methods}>
          <CardForm label="Informasi Umum Proposal Kegiatan">
            <div className="flex flex-col gap-4">
              <AtomSelect
                controllerName={`awp_id`}
                className={`mt-2 w-full rounded-md`}
                options={optionAWP}
                label={
                  "Pilih Proyek yang Akan Diajukan Proposal Program dan Atau Kegiatan"
                }
                placeholder={"Pilih salah satu"}
                required={true}
                onMenuScrollToBottom={reachBottom}
              />

              <InputForm
                controllerName={`proposal_title`}
                className={`py-2 px-4 mt-2 border w-full rounded-md`}
                label={"Judul Proposal"}
                required={true}
              />

              <div className="font-semibold text-[14px]">
                <label className="flex gap-1">Outcome</label>
                <ReactSelectCreatable
                  name="outcome"
                  isMulti
                  onChange={(e) => methods.setValue("outcome", e)}
                  className="text-sm"
                  options={outcomeOption}
                />
              </div>

              <div className="font-semibold text-[14px]">
                <label className="flex gap-1">Output</label>
                <ReactSelectCreatable
                  name="output"
                  isMulti
                  onChange={(e) => methods.setValue("output", e)}
                  className="text-sm"
                  options={outputOption}
                />
              </div>

              <InputForm
                controllerName={`description_proposal`}
                className={`py-2 px-4 mt-2 border w-full h-32 rounded-md`}
                label={
                  <div className="flex flex-col w-full gap-1">
                    <p>Deskripsi Proposal</p>
                    <div className="flex bg-[#FFFCF5] self-stretch p-4 gap-3 border border-[#F5AE70] rounded-xl">
                      <FiAlertTriangle color="#BE600E" size={20} />
                      <div className="flex flex-col gap-1">
                        <p className="font-medium text-[#8F480A] text-sm">
                          Jelaskan secara singkat & padat terkait isi dan
                          substansi dari proposal program dan atau kegiatan yang
                          diajukan
                        </p>
                      </div>
                    </div>
                  </div>
                }
                required={true}
                textArea={true}
              />

              <hr />
              <hr />

              <div className="space-y-4">
                <label className="font-semibold text-sm">Aktivitas Utama</label>
                {mainActivityFields.map((el, i) => (
                  <div
                    key={"main-activity-" + el?.id}
                    className={`flex ${mainActivityFields.length > 1 ? "gap-6" : ""
                      }`}
                  >
                    <InputForm
                      controllerName={`main_activity[${i}].val`}
                      className="flex-1 rounded-sm"
                    />
                    <div
                      className={
                        mainActivityFields.length > 1 ? "min-w-[3.5rem]" : ""
                      }
                    >
                      {i > 0 && (
                        <Button
                          onClick={() => removeMainActivityFields(i)}
                          className="bg-error-600 text-white w-fit"
                          label={<BiTrash size={20} />}
                        />
                      )}
                    </div>
                  </div>
                ))}
                <div className="flex justify-end w-full">
                  <Button
                    className="bg-white border-[#D0D5DD]"
                    onClick={() => appendMainActivityFields({ val: "" })}
                    label={
                      <div className="flex items-center self-center">
                        <FaPlus size={16} className="mr-2" />
                        Aktivitas Utama
                      </div>
                    }
                  />
                </div>
              </div>

              <div className="space-y-4">
                <label className="font-semibold text-sm">Tujuan Kegiatan</label>
                {activityGoalFields.map((el, i) => (
                  <div
                    key={"activity-goal-" + el?.id}
                    className={`flex ${activityGoalFields.length > 1 ? "gap-6" : ""
                      }`}
                  >
                    <InputForm
                      controllerName={`activity_goals[${i}].val`}
                      className="flex-1 rounded-sm"
                    />
                    <div
                      className={
                        activityGoalFields.length > 1 ? "min-w-[3.5rem]" : ""
                      }
                    >
                      {i > 0 && (
                        <Button
                          onClick={() => removeActivityGoalFields(i)}
                          className="bg-error-600 text-white w-fit"
                          label={<BiTrash size={20} />}
                        />
                      )}
                    </div>
                  </div>
                ))}
                <div className="flex justify-end w-full">
                  <Button
                    className="bg-white border-[#D0D5DD]"
                    onClick={() => appendActivityGoalFields({ val: "" })}
                    label={
                      <div className="flex items-center self-center">
                        <FaPlus size={16} className="mr-2" />
                        Tujuan Kegiatan
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </CardForm>

          <CardForm label="Lokasi Pelaksanaan Kegiatan">
            <div className="flex flex-col gap-6">
              {locationFields.map((item, index) => (
                <NestedLocation
                  index={index}
                  field={item}
                  alias={`location.[${index}]`}
                  locations={locationFields}
                  append={appendLocation}
                  remove={removeLocation}
                />
              ))}

              <div className="space-y-4">
                <label className="text-[#1D2939] font-semibold text-sm">
                  Peta Batasan Wilayah Pelaksanaan
                </label>
                <InputFormDropzone
                  name="regional_restrictions"
                  informationText="PDF, JPG, PNG, Docx, XLSX, PPD"
                  accept={{
                    "application/pdf": [],
                    "image/jpeg": [],
                    "image/png": [],
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                      [],
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                      [],
                    "application/vnd.cups-ppd": [],
                  }}
                  maxFiles={30}
                  maxSize={10}
                  multiple={false}
                />
              </div>
            </div>
          </CardForm>

          <CardForm label="Tentang Lokasi Pelaksanaan">
            <div className="flex flex-col gap-6">
              <InputFormRadio
                controllerName={`is_conversion_area`}
                label={
                  "apakah lokasi/ area pelaksanaan program/ kegiatan di atas termasuk ke dalam area/ kawasan konservasi?"
                }
                values={[
                  {
                    label: "ya",
                    value: true,
                  },
                  {
                    label: "tidak",
                    value: false,
                  },
                ]}
              />

              <InputForm
                controllerName={`location_conversion_area`}
                className={`py-2 px-4 mt-2 border w-1/3 rounded-md`}
                label={"Tuliskan Kawasan Konservasi Tersebut"}
                required={true}
                disabled={!methods.watch("is_conversion_area")}
              />

              <InputFormRadio
                controllerName={`already_have_permission`}
                label={
                  "Jika lokasi pelaksanaan program/ kegiatan termasuk ke dalam area/ kawasan konservasi,Apakah area/ kawasan konservasi tersebut tidak dilarang dan sudah memperoleh izin?"
                }
                values={[
                  {
                    label: "ya",
                    value: true,
                  },
                  {
                    label: "tidak",
                    value: false,
                  },
                ]}
              />
            </div>
          </CardForm>

          <CardForm label="Informasi Beneficiaries">
            <div className="flex flex-col gap-6">
              <QuestionBadge text="Apakah kegiatan ini melibatkan masyarakat adat?" />
              <InputFormRadio
                className={"-mt-6"}
                controllerName={`is_involve_indigenous_comunities`}
                values={[
                  {
                    label: "ya",
                    value: true,
                  },
                  {
                    label: "tidak",
                    value: false,
                  },
                ]}
              />

              <InputForm
                controllerName={`comunities_name`}
                className={`py-2 px-4 mt-2 border rounded-md`}
                label={
                  "Tuliskan Masyarakat Hukum Adat yang Dilibatkan Tersebut"
                }
                required={true}
                disabled={!methods.watch("is_involve_indigenous_comunities")}
              />

              <QuestionBadge text="Apakah kegiatan anda melibatkan beneficiaries dari perempuan dan kelompok rentan?" />
              <InputFormRadio
                className={"-mt-6"}
                controllerName={`is_involve_beneficiaries_from_woman`}
                values={[
                  {
                    label: "ya",
                    value: true,
                  },
                  {
                    label: "tidak",
                    value: false,
                  },
                ]}
              />

              <QuestionBadge text="Berapa jumlah beneficiaries yang dilibatkan dalam kegiatan ini?" />

              <div className="grid grid-cols-3 gap-6">
                <InputForm
                  controllerName={`total_beneficiaries.female`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label={"Jumlah Perempuan"}
                />
                <InputForm
                  controllerName={`total_beneficiaries.male`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label={"Jumlah Laki-laki"}
                />
                <InputForm
                  controllerName={`total_beneficiaries.total`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label={"Total"}
                  disabled
                />
              </div>

              <div className="space-y-4">
                <label className="text-[#1D2939] font-semibold text-sm">
                  Upload dokumen Daftar beneficiaries
                  <span className="ml-1 text-error-500 text-sm">*</span>
                </label>
                <InputFormDropzone
                  name="file_beneficiaries_document"
                  informationText="PDF, JPG, PNG, Docx, XLSX, PPD"
                  accept={{
                    "application/pdf": [],
                    "image/jpeg": [],
                    "image/png": [],
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                      [],
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                      [],
                    "application/vnd.cups-ppd": [],
                  }}
                  maxFiles={30}
                  maxSize={10}
                  multiple={false}
                />
              </div>
            </div>
          </CardForm>

          <CardForm label="Informasi Environmental & Social Lifeguard">
            <div className="space-y-4">
              <label className="text-[#1D2939] font-semibold text-sm">
                E & S Safeguard yang akan diterapkan
                <span className="ml-1 text-error-500 text-sm">*</span>
              </label>
              <ReactSelectCreatable
                name="safeGuards"
                isMulti
                onChange={(e) => methods.setValue("safeGuards", e)}
                className={`text-sm ${methods?.formState?.errors?.safeGuards
                  ? `border border-error-600 rounded`
                  : ``
                  }`}
                options={ispOption}
              />
              <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                {methods?.formState?.errors?.safeGuards?.message}
              </div>
            </div>
          </CardForm>

          <CardForm label="Rencana Anggaran Biaya">
            <div className="flex flex-col gap-6">
              <InputForm
                controllerName={`fund_application_value`}
                className={`py-2 px-4 mt-2 border w-1/3 rounded-md`}
                label={"Nilai Pengajuan Dana (Rp)"}
                required={true}
                showPrefix={true}
                prefix={"Rp. "}
                onChangeInput={(e) => {
                  methods.setValue(
                    "fund_application_value",
                    convertMoney(e.target.value)
                  );
                }}
              />

              <div className="space-y-4">
                <label className="text-[#1D2939] font-semibold text-sm">
                  Upload dokumen RAB
                  <span className="ml-1 text-error-500 text-sm">*</span>
                </label>
                <InputFormDropzone
                  name="rab_documents"
                  informationText="PDF, JPG, PNG, Docx, XLSX, PPD"
                  accept={{
                    "application/pdf": [],
                    "image/jpeg": [],
                    "image/png": [],
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                      [],
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                      [],
                    "application/vnd.cups-ppd": [],
                  }}
                  maxFiles={30}
                  maxSize={10}
                  multiple={false}
                />
              </div>
            </div>
          </CardForm>

          <CardForm label="Informasi Pengusul">
            <div className="flex flex-col gap-6">
              {applicationInformationFields.map((el, i) => (
                <div
                  key={"application-information-" + el.id}
                  className="flex gap-6"
                >
                  <div className="grid grid-cols-4 gap-6 w-[95%]">
                    <InputForm
                      controllerName={`applicant_information[${i}].name`}
                      className={`py-2 px-4 mt-2 border rounded-md`}
                      label={"Nama"}
                      required={true}
                    />
                    <InputForm
                      controllerName={`applicant_information[${i}].NIDN_NIP`}
                      className={`py-2 px-4 mt-2 border rounded-md`}
                      label={"NIDN/NIP/NIK"}
                      required={true}
                    />
                    <InputForm
                      controllerName={`applicant_information[${i}].description`}
                      className={`py-2 px-4 mt-2 border rounded-md`}
                      label={"Deskripsi"}
                    />
                    <InputForm
                      controllerName={`applicant_information[${i}].position`}
                      className={`py-2 px-4 mt-2 border rounded-md`}
                      label={"Jabatan"}
                      required={true}
                    />
                  </div>
                  {i > 0 && (
                    <div className="flex items-end w-[5%]">
                      <Button
                        onClick={() => removeApplicationInformationFields(i)}
                        className="bg-error-600 text-white w-fit"
                        label={
                          <div>
                            <BiTrash size={20} />
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
              ))}
              <hr />
              <div className="flex justify-end">
                <Button
                  onClick={() =>
                    appendApplicationInformationFields({
                      name: "",
                      NIDN_NIP: null,
                      description: "",
                      position: "",
                    })
                  }
                  className="bg-white w-fit"
                  label={
                    <div className="flex items-center gap-2">
                      <BiPlus size={20} />
                      <p>Informasi Pengusul</p>
                    </div>
                  }
                />
              </div>
            </div>
          </CardForm>

          <CardForm label="Dokumen Pendukung">
            <div className="space-y-4">
              <label className="text-[#1D2939] font-semibold text-sm">
                Upload dokumen pendukung
              </label>
              <InputFormDropzone
                name="additional_document"
                informationText="PDF, JPG, PNG, Docx, XLSX, PPD"
                accept={{
                  "application/pdf": [],
                  "image/jpeg": [],
                  "image/png": [],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [],
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    [],
                  "application/vnd.cups-ppd": [],
                }}
                maxFiles={30}
                maxSize={100}
                multiple
              />
            </div>
          </CardForm>
        </FormProvider>

        <div className="flex justify-between sticky w-full bottom-4 py-5 px-10 bg-white border border-gray-200 rounded-3xl">
          <Button
            label={
              <div className="flex items-center gap-2">
                <BiArrowBack />
                <span>Kembali</span>
              </div>
            }
            size="md"
            className="bg-white"
            onClick={() => navigate("/proposal/konsep-notes")}
          />
          <Button
            label="Submit"
            size="md"
            onClick={() => methods.handleSubmit(submitForm)()}
            className="bg-primary-600 hover:bg-primary-600/90 text-white"
          />
        </div>
      </div>
    </>
  );
};

const NestedLocation = ({ field, index, alias, locations, append, remove }) => {
  const provinceId = useWatch({
    name: `${alias}.province`,
  });

  const cityId = useWatch({
    name: `${alias}.cities`,
  });

  const districtId = useWatch({
    name: `${alias}.district`,
  });

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-4">
        <SelectFDBProvince
          controllerName={`${alias}.province`}
          className="basic-single flex-1"
          label="Provinsi"
          required
          placeholder="Provinsi"
        />
        <SelectFDBCity
          controllerName={`${alias}.cities`}
          className="basic-single flex-1"
          label="Kota/Kabupaten"
          required
          placeholder="Kota/Kabupaten"
          provinceId={provinceId?.value}
        />
        <SelectFDBDistrict
          controllerName={`${alias}.district`}
          className="basic-single flex-1"
          label="Kecamatan"
          placeholder="Kecamatan"
          cityId={cityId?.value}
        />
        <SelectFDBVillage
          controllerName={`${alias}.village`}
          className="basic-single flex-1"
          label="Kelurahan/Desa"
          placeholder="Kelurahan/Desa"
          districtId={districtId?.value}
        />
      </div>
      {/* <div className="flex flex-col gap-2">
        <label className="text-sm font-semibold">Titik Lokasi Alamat</label>
        <div className="flex bg-[#FFFCF5] self-stretch p-4 gap-3 border border-[#F5AE70] rounded-xl">
          <FiAlertTriangle color="#BE600E" size={20} />
          <div className="flex flex-col gap-1">
            <p className="font-medium text-[#8F480A] text-sm">
              Tandai Lokasi Sesuai Alamat
            </p>
          </div>
        </div>
        <Button
          type="button"
          className="bg-white"
          label={
            <div className="flex gap-2 items-center">
              <BsMap size={20} />
              <p>Pilih Lewat Peta</p>
            </div>
          }
        />
      </div> */}

      {/* <div className="grid grid-cols-2 gap-6">
        <InputForm
          controllerName={`${alias}.latitude`}
          className={`w-full rounded-md `}
          label={'Latitude'}
          placeholder={'Latitude'}
        />
        <InputForm
          controllerName={`${alias}.longitude`}
          className={`w-full rounded-md `}
          label={'Longitude'}
          placeholder={'Longitude'}
        />
      </div> */}
      <hr />
      <div className="flex items-center justify-center gap-4">
        {locations.length > 1 && (
          <>
            <Button
              className="rounded-lg border border-error-300 py-2.5 px-4"
              onClick={() => {
                remove(index);
              }}
              label={
                <div className="flex gap-2 items-center text-sm text-error-700">
                  <FaTrashAlt size={20} className="text-error-700" />
                  Hapus
                </div>
              }
            />
          </>
        )}
        {index === findLastIndex(locations) && (
          <Button
            className="bg-white"
            onClick={() =>
              append({
                province: null,
                district: null,
                village: null,
                city: null,
                latitude: "",
                longitude: "",
              })
            }
            label={
              <div className="flex gap-2 items-center">
                <BsPlus size={20} />
                <p>Lokasi Lain</p>
              </div>
            }
          />
        )}
      </div>
      <hr />
    </div>
  );
};

export default KonsepNotesForm;
