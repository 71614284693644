import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import AtomSelect from "../../../atoms/Select";
import { getVillageFDBService } from "services/fdb/region";
import { getWardService } from "services/masterData/region";

let HAS_MORE = false;

const SelectFDBVillage = React.forwardRef((props, ref) => {
  const { className, districtId, ...restProps } = props;

  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);

  const { data } = useQuery({
    queryKey: ["village-fdb", districtId, page],
    queryFn: async () => {
      // TODO: Adjust logic once actual api exist
      const res = await getWardService();
      if (res?.data?.data?.meta?.next_page_url) HAS_MORE = true;
      else HAS_MORE = false;
      const arr = res?.data?.data
      const filter = arr?.filter?.(ward => {
        return ward?.idKecamatan === districtId;
      }) || [];
      return filter || [];
    },
    enabled: !!districtId && !props.asText,
    retry: false,
    onSuccess: (data) => {
      setOptions((curr) => [...curr, ...data.map(x => ({ value: x.id, label: x?.namaKelurahan }))]);
    },
  });

  useEffect(() => {
    setOptions([]);
    setPage(1);
  }, [districtId]);

  const fetchMore = () => {
    if (HAS_MORE) {
      setPage((curr) => curr + 1);
    }
  }

  return (
    <AtomSelect
      options={options}
      className={["basic-single mt-2 flex-1", className].join(" ")}
      onMenuScrollToBottom={fetchMore}
      {...restProps}
    />
  );
});

export default SelectFDBVillage;
