import React, { Fragment, useEffect } from 'react';
import {
  RiBriefcaseLine,
  RiComputerLine,
  RiEdit2Line,
  RiFileAddLine,
  RiFileTextLine,
  RiMapPinLine,
  RiShieldKeyholeFill,
  RiUploadCloud2Line,
  RiErrorWarningLine,
  RiCheckLine,
} from 'react-icons/ri';
import { useNavigate } from 'react-router';

import { Button, HeaderLabel } from 'components';
import BreadCrumbs from 'components/molecules/Breadcrumbs';
import AlertBlankData from 'components/molecules/Alert';

import GeneralInformation from 'assets/illustrations/General_Informasi.svg';
import Beneficiaries from 'assets/illustrations/Beneficiaries.svg';
import Dokumen_Pendukung from 'assets/illustrations/Dokumen_Pendukung.svg';
import Lokasi_Pelaksanaan from 'assets/illustrations/Lokasi_Pelaksanaan.svg';
import Rencana_Kerja from 'assets/illustrations/Rencana_Kerja.svg';
import Safeguards from 'assets/illustrations/Safeguards.svg';
import { useDispatch } from 'react-redux';
import { resetStateCfP, setFormEditable } from 'app/actions';

const GRID_DATAS = [
  {
    id: 'general-information',
    title: 'General Informasi',
    iconClassName: 'border-orangedark-100 bg-orangedark-600',
    icon: <RiComputerLine size={28} className="text-white" />,
    illustration: GeneralInformation,
    link: '/proposal/call-for-proposal/general-information',
  },
  {
    id: 'beneficiaries',
    title: 'Beneficiaries',
    iconClassName: 'border-[#D1E9FF] bg-[#1570EF]',
    icon: <RiFileTextLine size={28} className="text-white" />,
    illustration: Beneficiaries,
    link: '/proposal/call-for-proposal/beneficiaries',
  },
  {
    id: 'location',
    title: 'Lokasi Pelaksanaan',
    iconClassName: 'border-[#D3F8DF] bg-[#099250]',
    icon: <RiMapPinLine size={28} className="text-white" />,
    illustration: Lokasi_Pelaksanaan,
    link: '/proposal/call-for-proposal/location',
  },
  {
    id: 'workplan',
    title: 'Rencana Kerja',
    iconClassName: 'border-[#FEF7C3] bg-[#CA8504]',
    icon: <RiBriefcaseLine size={28} className="text-white" />,
    illustration: Rencana_Kerja,
    link: '/proposal/call-for-proposal/workplan',
  },
  {
    id: 'safeguards',
    title: 'E & S Safeguards',
    iconClassName: 'border-[#EBE9FE] bg-[#6938EF]',
    icon: <RiShieldKeyholeFill size={28} className="text-white" />,
    illustration: Safeguards,
    link: '/proposal/call-for-proposal/safeguards',
  },
  {
    id: 'document',
    title: 'Dokumen Pendukung',
    iconClassName: 'border-[#FCE7F6] bg-[#DD2590]',
    icon: <RiFileAddLine size={28} className="text-white" />,
    illustration: Dokumen_Pendukung,
    link: '/proposal/call-for-proposal/documents',
  },
];

const NewProposal = ({ type = 'new', isComplete = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetStateCfP());
    dispatch(setFormEditable(true));
  }, []);

  return (
    <Fragment>
      <div className="space-y-6 w-full">
        <div className="bg-white rounded-xl p-10">
          <div className="flex flex-col w-full">
            <BreadCrumbs
              routes={[
                {
                  label: 'Call For Proposal',
                  path: `/proposal/call-for-proposal`,
                },
                {
                  label: 'Tambah Proposal',
                },
              ]}
            />
            <div className="flex mt-5 gap-4">
              <div className="flex-1">
                <HeaderLabel
                  text="Formulir Proposal"
                  description="Formulir Proposal"
                />
              </div>
              <Button
                type="button"
                className="h-11 rounded-lg bg-secondary-orange-600 border border-secondary-orange-600 text-white text-sm px-6 py-2.5 disabled:!text-gray-500 disabled:!bg-gray-300 disabled:!border-gray-300"
                disabled
                label={
                  <div className="flex items-center gap-2">
                    <RiUploadCloud2Line />
                    <span className="font-semibold text-sm">
                      Upload Semua Persyaratan
                    </span>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <AlertBlankData
            title={`Lengkapi Dokumen`}
            body={`Anda bisa upload jika semua dokumen kategori di bawah ini sudah dilengkapi.`}
          />
        </div>
        <div className="pt-[46px] grid gap-x-6 gap-y-[50px] grid-cols-[repeat(auto-fit,minmax(275px,1fr))]">
          {GRID_DATAS.map((x, key) => (
            <div
              key={x.id}
              className="relative rounded-xl px-6 pt-0 pb-4 border border-gray-200 shadow-[0px 20px 40px 0px rgba(51, 40, 123, 0.04)]"
            >
              <div className="flex justify-center w-full -mt-10">
                <div
                  className={`rounded-[100%] p-3.5 border-[10px] w-fit text-white ${x.iconClassName}`}
                >
                  {x.icon}
                </div>
              </div>
              <div className="text-gray-900 text-center font-[500] mb-2 mt-3">
                {x.title}
              </div>
              <div className="space-y-6">
                <div className="flex w-full justify-center">
                  <img src={x.illustration} alt={x.id} />
                </div>
                <AlertBlankData
                  title={`Lengkapi Dokumen`}
                  body={`Lengkapi kesesuaian dokumen administrasi yang dipersyaratkan.`}
                />
              </div>
              {type === 'edit' ? (
                isComplete ? (
                  <div className="mt-6 rounded-2xl gap-1 bg-[#ECFDF3] py-1 pr-3 pl-2.5 flex items-center w-fit">
                    <RiCheckLine size={16} className="text-[#027A48]" />
                    <div className="text-[#027A48] text-sm font-[500]">
                      Data Sudah Dilengkapi
                    </div>
                  </div>
                ) : (
                  <div className="mt-6 rounded-2xl gap-1 bg-[#FFFAEB] py-1 pr-3 pl-2.5 flex items-center w-fit">
                    <RiErrorWarningLine size={16} className="text-[#B54708]" />
                    <div className="text-[#B54708] text-sm font-[500]">
                      Data Belum Lengkap
                    </div>
                  </div>
                )
              ) : null}
              <hr className="mb-4 mt-6" />
              {type === 'edit' ? (
                <Button
                  type="button"
                  className="rounded-lg border-gray-300 bg-white text-gray-700 shadow py-2 px-3.5 flex-1 w-full"
                  label={
                    <div className="flex gap-2 justify-center items-center">
                      <RiEdit2Line size={20} />
                      Ubah
                    </div>
                  }
                  onClick={() => navigate(x.link)}
                />
              ) : (
                <Button
                  type="button"
                  className="rounded-lg bg-primary-600 text-white shadow py-2 px-3.5 flex-1 w-full font-semibold"
                  label="Lengkapi"
                  onClick={() => navigate(x.link)}
                // disabled={x.title !== 'General Informasi' && !isComplete}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default NewProposal;
