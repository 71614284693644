const config = {
  BASE_URL:
    process.env.REACT_APP_BASE_URL || "https://bpldh-api.groot.id/api/fdb",
};

export const config_daprog = {
  BASE_URL: `${process.env.REACT_APP_BASE_URL}/api/dana-program`,
};

export const general_config = {
  BASE_URL: `${process.env.REACT_APP_BASE_URL}/api`,
};

export const config_master = {
  BASE_URL: `${process.env.REACT_APP_BASE_URL}/api/master`,
};

export const config_fdb = {
  BASE_URL: `${process.env.REACT_APP_BASE_URL}/api/fdb`,
};

export const config_fdb_v2 = {
  BASE_URL: `${process.env.REACT_APP_BASE_URL}/api/fdb-v2`,
};

export const AUTOSAVE_DURATION = parseFloat(
  process.env.REACT_APP_AUTOSAVE_DURATION || 5 * 60 * 1000
); // in ms. DEFAULT: 5 min
export const MAX_DOC_UPLOAD = parseFloat(
  process.env.REACT_APP_MAX_DOC_UPLOAD || 10 * 1024 * 1024
); // in bytes. DEFAULT: 10MB
export const MAX_IMG_UPLOAD = parseFloat(
  process.env.REACT_APP_MAX_IMG_UPLOAD || 10 * 1024 * 1024
); // in bytes. DEFAULT: 10MB
export const DOC_UPLOAD_EXT =
  process.env.REACT_APP_DOC_UPLOAD_EXT?.trim()?.split(",") || [
    "jpg",
    "png",
    "jpeg",
    "pdf",
    "docx",
  ];
export const IMG_UPLOAD_EXT =
  process.env.REACT_APP_IMG_UPLOAD_EXT?.trim()?.split(",") || [
    "jpg",
    "png",
    "jpeg",
  ];

export default config;
